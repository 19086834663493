// material-ui
import { alpha, createTheme } from '@mui/material/styles';

// third-party
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors';

// project import
// import ThemeOption from './theme';
import { ThemeMode } from 'config';
import chroma from 'chroma-js';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  const colors = mode === ThemeMode.DARK ? presetDarkPalettes : presetPalettes;

  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
  ];
  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  let greyConstant = ['#fafafb', '#e6ebf1'];

  if (mode === ThemeMode.DARK) {
    greyPrimary = ['#000000', '#141414', '#1e1e1e', '#595959', '#8c8c8c', '#bfbfbf', '#d9d9d9', '#f0f0f0', '#f5f5f5', '#fafafa', '#ffffff'];
    // greyPrimary.reverse();
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    greyConstant = ['#121212', '#d3d8db'];
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const { grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  let productionBase = '#40B686';
  let testingBase = '#D9A76B';
  let developmentBase = '#7B9BCC';
  let researchBase = '#BA99C5';

  // Colors aligned with Apollo "dark" colors
  // Primary color is "true blue" to contrast light backgrounds
  let primaryBase = '#461e96';
  let errorBase = '#9E2305';
  let warningBase = '#FFA400';
  let infoBase = '#0073BE';
  let successBase = '#007F50';
  // let secondaryBase = '#E6008C';

  if (mode === ThemeMode.DARK) {
    // Colors aligned with Apollo "core" colors
    // Primary color is "core blue" to contrast dark backgrounds
    primaryBase = '#00A0E0';
    errorBase = '#E22F00';
    warningBase = '#FAEB1E';
    infoBase = '#00B4E6';
    successBase = '#00DC8C';
  }

  const paletteColor = {
    primary: {
      highlight: mode === ThemeMode.DARK ? '#32203B' : '#EEEDFC',
      lighter: chroma(primaryBase).brighten(3.2).hex(),
      100: chroma(primaryBase).brighten(2.6).hex(),
      200: chroma(primaryBase).brighten(2.4).hex(),
      light: chroma(primaryBase).brighten(2.0).hex(),
      400: chroma(primaryBase).brighten(1.6).hex(),
      main: primaryBase,
      dark: chroma(primaryBase).darken(0.3).hex(),
      700: chroma(primaryBase).darken(0.8).hex(),
      darker: '#311569',
      // darker: chroma(primaryBase).darken(1.1).hex(),
      900: chroma(primaryBase).darken(1.4).hex(),
      contrastText
    },
    secondary: {
      lighter: chroma('#E6008C').brighten(3.2).hex(),
      100: chroma('#E6008C').brighten(2.6).hex(),
      200: chroma('#E6008C').brighten(2.4).hex(),
      light: chroma('#E6008C').brighten(2.0).hex(),
      400: chroma('#E6008C').brighten(1.6).hex(),
      main: '#E6008C',
      dark: chroma('#E6008C').darken(0.3).hex(),
      700: chroma('#E6008C').darken(0.8).hex(),
      darker: chroma('#E6008C').darken(1.1).hex(),
      900: chroma('#E6008C').darken(1.4).hex(),
      contrastText
    },
    error: {
      lighter: chroma(errorBase).brighten(1.5).hex(),
      light: chroma(errorBase).brighten(0.6).hex(),
      main: errorBase,
      dark: chroma(errorBase).darken(0.3).hex(),
      darker: chroma(errorBase).darken(0.6).hex(),
      contrastText
    },
    warning: {
      lighter: chroma(warningBase).brighten(1.5).hex(),
      light: chroma(warningBase).brighten(0.6).hex(),
      main: warningBase,
      dark: chroma(warningBase).darken(0.3).hex(),
      darker: chroma(warningBase).darken(0.6).hex(),
      contrastText: greyColors[100]
    },
    info: {
      lighter: chroma(infoBase).brighten(1.5).hex(),
      light: chroma(infoBase).brighten(0.6).hex(),
      main: infoBase,
      dark: chroma(infoBase).darken(0.3).hex(),
      darker: chroma(infoBase).darken(0.6).hex(),
      contrastText
    },
    success: {
      lighter: chroma(successBase).brighten(1.5).hex(),
      light: chroma(successBase).brighten(0.6).hex(),
      main: successBase,
      dark: chroma(successBase).darken(0.3).hex(),
      darker: chroma(successBase).darken(0.6).hex(),
      contrastText
    },
    production: {
      main: productionBase,
      contrastText
    },
    testing: {
      main: testingBase,
      contrastText
    },
    development: {
      main: developmentBase,
      contrastText
    },
    research: {
      main: researchBase,
      contrastText
    },
    grey: greyColors
  };

  const apiMethodColor = {
    get: mode === ThemeMode.DARK ? chroma('#4caf8a').darken(0.3).hex() : '#4caf8a',
    post: mode === ThemeMode.DARK ? chroma('#8a4caf').darken(0.3).hex() : '#8a4caf',
    put: mode === ThemeMode.DARK ? chroma('#af8a4c').darken(0.3).hex() : '#af8a4c',
    delete: mode === ThemeMode.DARK ? chroma('#af4c4c').darken(0.3).hex() : '#af4c4c'
  };

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      apiMethodColor,
      ...paletteColor,
      text: {
        primary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey[700],
        secondary: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey[500],
        aboutColor: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[500],
        disabled: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[400],
        greenThreshold: '#008450',
        redThreshold: '#B81D13',
        yellowThreshold: '#EFB700'
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: mode === ThemeMode.DARK ? alpha(paletteColor.grey[900], 0.05) : paletteColor.grey[200],
      background: {
        paper: mode === ThemeMode.DARK ? paletteColor.grey[100] : paletteColor.grey[0],
        default: paletteColor.grey.A50
      }
    }
  });
};

export default Palette;
