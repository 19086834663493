import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { Box, Container, Fab, Toolbar } from '@mui/material';
import { useTheme, ThemeProvider } from '@mui/material/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';

// project import
import Header from './Header';
import Footer from './Footer';

import useConfig from 'hooks/useConfig';
import useAuth from 'hooks/useAuth';

import { NavigationProvider } from 'contexts/NavigationContext';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const { container } = useConfig();

  // determine whether the user is part of the ED&A group
  const [isEDAGroup, setIsEDAGroup] = useState(false);
  const { isLoggedIn, user } = useAuth();

  // function to save the path of the user and open the feedback form page
  const navigateToFeedback = () => {
    // Open the feedback page in a new tab
    window.open('/feedback', '_blank');
  };

  useEffect(() => {
    const hasAllowedGroup = () => {
      // ED&A Department & edna_pariveda_team
      const allowedGroups = ['576e19d7-4867-4b55-9518-50e1d77b177f', '8f331f38-b398-4bff-94b5-153ff854c28d'];
      const extractedGroups = user.AD_groups.map((group) => group.id);
      return extractedGroups.some((group) => allowedGroups.includes(group));
    };

    if (user && hasAllowedGroup) {
      setIsEDAGroup(true);
    } else {
      setIsEDAGroup(false);
    }
  }, [isLoggedIn, user]);

  return (
    <ThemeProvider theme={theme}>
      <NavigationProvider>
        <Box sx={{ display: 'flex', width: '100%' }}>
          {isEDAGroup && (
            <Fab
              onClick={navigateToFeedback}
              color="secondary"
              variant="extended"
              sx={{
                position: 'fixed',
                bottom: '24px',
                right: '24px',
                zIndex: [2000]
              }}
            >
              Give Feedback <FeedbackIcon sx={{ ml: 1 }} />
            </Fab>
          )}
          <Header />
          <Box component="main" sx={{ width: 'calc(100%)', flexGrow: 1 }}>
            <Toolbar />

            <Container
              maxWidth="100%"
              sx={{
                ...(container && { px: { xs: 0, sm: 0 } }),
                position: 'relative',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Outlet />
            </Container>
            <Footer />
          </Box>
        </Box>
      </NavigationProvider>
    </ThemeProvider>
  );
};

export default MainLayout;
