// material-ui
import { Grid, Button, Typography } from '@mui/material';
import { ArrowCircleUpOutlined } from '@mui/icons-material';

// ==============================|| Footer Actions - MAIN ||============================== //

const FooterActions = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container direction="row" justifyContent="flex-end">
      {/* Disclaimer */}
      <Grid item xs={9}>
        <Typography variant="body2" color="white">
          ED&A data is confidential. Do not share outside organization.
        </Typography>
      </Grid>

      {/* Back to Top button */}
      <Grid item xs={3} sx={{ textAlign: 'right' }}>
        <Button
          variant="text"
          color="inherit"
          sx={{ '& .MuiSvgIcon-root': { fontSize: '1.5rem' } }}
          endIcon={<ArrowCircleUpOutlined style={{ color: 'white' }} />}
          onClick={handleScrollToTop}
        >
          <Typography noWrap variant="button" color="white">
            Back to Top
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterActions;
