import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const NavigationContext = createContext();

export const useNavigation = () => {
  return useContext(NavigationContext);
};

export const NavigationProvider = ({ children }) => {
  const location = useLocation();
  const [navigationChain, setNavigationChain] = useState([]);

  useEffect(() => {
    const currentPath = location.pathname;
    const storedChain = JSON.parse(localStorage.getItem('navigationChain')) || [];

    // Add the current path to the chain
    if (storedChain[storedChain.length - 1] !== currentPath) {
      const updatedChain = [...storedChain, currentPath];
      setNavigationChain(updatedChain);
      localStorage.setItem('navigationChain', JSON.stringify(updatedChain));
    } else {
      setNavigationChain(storedChain);
    }
  }, [location.pathname]);

  return <NavigationContext.Provider value={navigationChain}>{children}</NavigationContext.Provider>;
};

NavigationProvider.propTypes = {
  children: PropTypes.node
};
