import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { ActiveDirectoryProvider as AuthProvider } from 'contexts/ActiveDirectoryContext';

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <AuthProvider>
        <>
          <Notistack>
            <Routes />
            <Snackbar />
          </Notistack>
        </>
      </AuthProvider>
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
