import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Stack, Toolbar } from '@mui/material';

// project import
import FooterActions from './FooterActions';
import FooterLinks from './FooterLinks';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => {
  const theme = useTheme();

  // footer content
  const footerActions = useMemo(() => <FooterActions />, []);
  const footerLinks = useMemo(() => <FooterLinks />, []);

  const toolbarStyle = {
    display: 'flex',
    flexDirection: 'column', // Set flexDirection to 'column'
    backgroundColor: theme.palette.primary.dark,
    bottom: 0,
    zIndex: 1200,
    padding: '4vh 8vw'
  };

  const dividerStyle = {
    backgroundColor: 'white',
    mt: 2, // Add margin to separate the divider from other elements
    mb: 2 // Add margin to separate the divider from other elements
  };

  return (
    <Toolbar style={toolbarStyle}>
      <Stack spacing="24px" width="100%">
        {footerActions}

        <Divider sx={dividerStyle} />

        {footerLinks}
      </Stack>
    </Toolbar>
  );
};

export default Footer;
