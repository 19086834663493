// ==============================|| THEME CONSTANT  ||============================== //

export const APP_DEFAULT_PATH = '/';
export const API_BASE_URL = 'https://localhost:5000';
export const HORIZONTAL_MAX_ITEM = 6;

export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark'
};

// ==============================|| THEME CONFIG  ||============================== //

const config = {
  /**
   * The props used for the theme font-style.
   * We provide static below options -
   * `'Inter', sans-serif`
   * `'Poppins', sans-serif`
   * `'Roboto', sans-serif`
   * `'Public Sans', sans-serif` (default)
   */
  fontFamily: `'Public Sans', sans-serif`,

  /**
   * the props used for theme container.
   * the container centers your content horizontally. It's the most basic layout element.
   * default - true which show container
   * false - will show fluid
   */
  container: true,

  mode: ThemeMode.LIGHT,

  apiBaseUrl: API_BASE_URL,

  presetColor: 'amerisourcebergen'
};

export default config;
