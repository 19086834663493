// material-ui

// project import

// ==============================|| CUSTOM THEME - Reagraph  ||============================== //

const ReagraphTheme = (theme) => {
  return {
    canvas: {
      background: theme.palette.primary.darker,
      fog: '#fff'
    },
    node: {
      fill: theme.palette.primary.main,
      activeFill: '#FFFFFF',
      opacity: 1,
      selectedOpacity: 1,
      inactiveOpacity: 1,
      label: {
        color: '#FFFFFF',
        stroke: theme.palette.primary.darker,
        activeColor: '#FFFFFF'
      },
      subLabel: {
        color: '#2A6475',
        stroke: '#eee',
        activeColor: '#FB09A3'
      }
    },
    lasso: {
      border: '1px solid #55aaff',
      background: 'rgba(75, 160, 255, 0.1)'
    },
    ring: {
      fill: '#FFFFFF',
      activeFill: '#FFFFFF'
    },
    edge: {
      fill: '#FFFFFF',
      activeFill: '#FFFFFF',
      opacity: 0.65,
      selectedOpacity: 0.65,
      inactiveOpacity: 0.65,
      label: {
        stroke: 'None',
        color: '#FFFFFF',
        activeColor: '#1DE9AC'
      }
    },
    arrow: {
      fill: '#D8E6EA',
      activeFill: '#1DE9AC'
    },
    cluster: {
      stroke: '#D8E6EA',
      opacity: 1,
      selectedOpacity: 1,
      inactiveOpacity: 0.1,
      label: {
        stroke: '#fff',
        color: '#2A6475'
      }
    }
  };
};

export default ReagraphTheme;
