import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PortfolioDropdown from '../PortfolioDropdown';
import DataGovernanceDropdown from '../DataGovernanceDropdown';
import NavButton from '../NavButton';

const NavDropdown = ({ label, path }) => {
  const buttonRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [horizontalOffset, setHorizontalOffset] = useState(null);

  // Calculate the width of the popper dynamically
  const popperWidth = window.innerWidth * 0.9;
  const popperHeight = window.innerHeight * 0.7;

  // Calculate the proper offset for the popper dynamically
  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setHorizontalOffset(-16 - rect.x + (window.innerWidth - popperWidth) / 2);
    }
  }, [showTooltip, popperWidth]);

  const DropdownTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      boxShadow: null,
      fontSize: 11
    }
  }));

  const handleButtonClick = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <Box>
      <DropdownTooltip
        interactive="true"
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        placement="bottom-start"
        leaveDelay={160}
        disableFocusListener
        sx={{ backgroundColor: 'transparent' }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [horizontalOffset, -10]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                boundary: 'viewport'
              }
            },
            {
              name: 'flip',
              options: {
                enabled: false
              }
            }
          ]
        }}
        title={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#311569',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.25)',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              padding: '0px 100px',
              borderRadius: '0px 0px 4px 4px',
              width: popperWidth,
              margin: '0 auto',
              height: popperHeight
            }}
          >
            <Box width="100%" display="flex" p={1}>
              {label === 'Portfolio' && <PortfolioDropdown height={popperHeight} />}
              {label === 'Data Governance' && <DataGovernanceDropdown height={popperHeight} width={popperWidth} />}
            </Box>
          </Box>
        }
      >
        <NavButton label={label} isdropdown={'true'} href={path} ref={buttonRef} onClick={handleButtonClick} />
      </DropdownTooltip>
    </Box>
  );
};

NavDropdown.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string
};

export default NavDropdown;
