import { azure, powerBI, databricks, python, kafka, neo4j, react, spark, vectorDatabase, mL, bV, genAI, kG, playbooks, bEV } from './tools';

export const PROJECTS_BASE_PATH = '/portfolio';

export const products = {
  // 'TEMPLATE': {
  //   key: 'TEMPLATE'
  //   type: 'Decision Model' || 'Product',
  //   title: '',
  //   sponsor: '',
  //   businessCase: '',
  //   problemStatement: '',
  //   goal: '',
  //   valueStory: '',
  //   valueRealization: [
  //     ''
  //   ],
  //   hasLink: false,
  //   linkHeader: '',
  //   linkLabel: '',
  //   link: '',
  //   relatedProjects: [],
  //   tools: [],
  //   enablers: []
  // },
  'bid-tool': {
    key: 'bid-tool',
    type: 'Product',
    title: 'Bid Tool',
    sponsor: 'Strategic Global Sourcing (SGS)',
    businessCase:
      'Category Managers must select, review and make decision on various bidding activities leveraging various systems, excel sheets and manual workarounds. All current activities are done manually with no record keeping and intelligence, inheriting creating a great deal of risk. ',
    problemStatement:
      'The manual processes limit intelligence, optimization and bolster an environment of risk and user dependency. The lack of record keeping, and automation has led to ambiguous decision-making habits.',
    goal: 'Construct a comprehensive Bid Tool encompassing the bid selection and bid decision processes to enhance and empower the Category Managers for more efficient bidding activities. The intent is to better serve our Category Managers through automating and optimizing their current daily bidding activities. ',
    valueStory:
      'Improve efficiency for the Sourcing Team to prioritize generic RX items that need to be sourced by providing cost and volume in an analytics tool',
    valueRealization: ['$8-12M in Proposed PharmaGen Harmonization'],
    linkHeader: 'Link to Product',
    linkLabel: 'BidTool',
    link: 'https://bidtooldev.azurewebsites.net',
    relatedProjects: ['supplier-risk-mapping', 'operating-income'],
    tools: [azure, react, databricks],
    enablers: []
  },
  'carbon-footprint': {
    key: 'carbon-footprint',
    type: 'Product',
    title: 'Carbon Footprint',
    businessCase:
      'We advise on efficient and responsible operations across our global supply chain, accelerating the delivery of medications and healthcare services. ED&A supports our ESG initiative to decarbonize in accordance with the White House Climate Pledge.',
    problemStatement:
      'Provide Cencora customers with quality, detailed information about the emissions impact that comes from activities related to purchasing and partnership with Cencora. This is for customers to be able comply with regulations and​ target how to reduce Scope 3 emissions.​',
    goal: 'We are committed to advancing our environmental, social and governance (ESG) initiatives to create healthier futures around the world. We strive to foster a positive impact on the planet and people centered on improving access and equity in healthcare.',
    valueStory: 'Advise on the alignment of data and metrics used to measure and track emissions (i.e., Scope 1 and Scope 2 emissions)',
    valueRealization: ['Environmental, Social and Governance (ESG)'],
    linkHeader: 'Link to Product',
    linkLabel: 'Carbon Footprint',
    relatedProjects: [],
    tools: [],
    enablers: []
  },
  'data-marketplace': {
    key: 'data-marketplace',
    type: 'Product',
    title: 'Data Marketplace',
    sponsor: 'Enterprise',
    businessCase:
      'A data insights marketplace, both internally and externally, enables organizations to increase operational efficiency, enhance decision-making, foster innovation through collaboration, contribute to ecosystem development, and monetize their data assets.',
    problemStatement:
      'The lack of a platform that enables the exchange of data assets by connecting data providers and consumers limits trusted data consumption for all enterprise users.',
    goal: 'Provide a platform for internal users’ visibility to available data sets, the quality of the data, and the data usage rights associated with the data. The Marketplace establishes a tailored end-user experience with underlying technical integrations of Cencora’s data products.',
    valueStory:
      'Utilize a real time data analytics tool to identify when product has been physically received but not scheduled for expedited receipt into inventory',
    valueRealization: ['Improved Experience'],
    linkHeader: 'Link to Product',
    linkLabel: 'Data Marketplace',
    relatedProjects: ['customer-360', 'customer-segmentation', 'product-segmentation'],
    tools: [azure, databricks, neo4j, powerBI, python, react],
    enablers: [kG],
    featured: false
  },
  dscsa: {
    key: 'dscsa',
    type: 'Product',
    title: 'Drug Supply Chain Security Act (DSCSA)',
    sponsor: 'Manufacturing Operations and Supply Chain',
    businessCase:
      'DSCSA will prohibit Cencora from acquiring, receiving, and reselling any improperly serialized generic and branded human health medication. By promptly identifying at-risk vendors and materials, Cencora will be able to implement proactive and coordinated strategies between SGS, RO, and Branded/Generic CM aiming to mitigate the likelihood of supply chain disruptions, and, by extension, reduce its financial and reputational risk exposure.',
    problemStatement:
      'SGS is actively working with suppliers to enforce DSCSA standards as well as the completeness and accuracy of the associated ATTP data. Despite extensive planning, it is unlikely that all vendors will succeed in serializing all their products by the FDA-mandated deadline. Adequate and coordinated risk mitigation strategies are needed to prevent supply chain disruptions.',
    goal: 'Evaluate vendor progress towards DSCSA compliance, identify vendors and materials at-risk of missing the FDA-mandated deadline, estimate Cencora’s financial risk exposure, and inform stakeholders for targeted and coordinated risk mitigation.',
    valueStory: 'Facilitate visibility to larger trends of operational performance issues that would impact the supply chain',
    valueRealization: ['Risk Management / Avoidance'],
    linkHeader: 'Link to Product',
    linkLabel: 'Drug Supply Chain Security Act (DSCSA)',
    relatedProjects: ['supplier-risk-mapping'],
    tools: [azure, databricks, kafka, python],
    enablers: []
  },
  'inv-visibility': {
    key: 'inv-visibility',
    type: 'Product',
    title: 'Inventory Visibility Enhancements',
    sponsor: 'Enterprise and Tiger Teams',
    businessCase: 'We need a product availability solution that analyzes omits and identifies inventory gaps.​',
    problemStatement: 'Model analyzes omits and assigns reason codes to better understand inventory gaps.​',
    goal: ['- Increased forecast accuracy ​', '- Reduction of omits and increased service level'],
    valueStory: 'Provide a model to analyze omits and assign reason codes to better understand inventory gaps ',
    valueRealization: [
      'Improved Experience - Increased forecast accuracy ',
      'Improved Experience - Reduction of omits and increased service level'
    ],
    linkHeader: 'Link to Product',
    linkLabel: 'Inventory Visibility Enhancements',
    relatedProjects: ['enterprise-certified-metrics', 'operating-income', 'supplier-risk-mapping'],
    tools: [neo4j],
    enablers: [kG, genAI]
  },
  'my-community-my-pharmacy': {
    key: 'my-community-my-pharmacy',
    type: 'Product',
    title: 'My Community, My Pharmacy',
    sponsor: 'Office of Diversity, Equity, and Inclusion (DEI)',
    businessCase:
      'Pharmacists require data and insights to make informed business decisions that help address healthcare disparities and underserved populations within their communities. Independent pharmacists often operate in areas with limited resources and face challenges in gathering and analyzing data about the patient populations they serve. ED&A partners with the Office of Diversity, Equity, and Inclusion (DEI) to provide actionable insights to Good Neighbor Pharmacy (GNP), a national independent pharmacy network offered through AmerisourceBergen, and field business coaches that inform action plans for improving pharmacy accessibility. ',
    problemStatement:
      'There are growing concerns in the U.S. over the limited access to pharmacies in certain areas, and if pharmacies can adequately address the needs of a growing disabled population. In the U.S. there are 150 counties where there is no pharmacy, and nearly 4.8 million people live in a county where there’s only one pharmacy for every 10,000 residents or more. In addition, approximately 1 out of 4 working-age adults have some type of disability. Cencora does not currently offer adequate data and tools to inform GNP regarding the prevalence of accessibility and disability barriers in the communities they serve, limiting their ability to address these healthcare disparities.',
    goal: 'Create a new analytics solution for GNP that creates awareness of local accessibility and disability barriers to care, informing enhanced business decisions and action plan recommendations provided by GNP solutions and the Cencora Health Equity Toolkit. ',
    valueStory: 'Provide visibility to locations of GNP community pharmacies to identify social vulnerability to underserved populations',
    valueRealization: ['Diversity, Equity, and Inclusion (DEI)'],
    linkHeader: 'Link to Product',
    linkLabel: 'My Community, My Pharmacy',
    link: 'https://pharmacyaccessibility.azurewebsites.net/',
    relatedProjects: ['customer-segmentation'],
    tools: [azure, databricks, react, vectorDatabase],
    enablers: [mL],
    featured: true
  },
  ndc: {
    key: 'ndc',
    type: 'Product',
    title: 'NDC Optimization Suite',
    sponsor: 'US Supply Chain and SGS',
    businessCase:
      "The National Distribution Center (NDC) is a key asset in Cencora's logistical network, large amounts of total network volume pass through the NDC which makes it imperative that it is operating efficiently.",
    problemStatement:
      "The NDC has a project maximum holiday operating capacity of 125K cases per day. The NDC's current daily target is 100k cases per day and is commonly constrained to 80k cases per day. This reduction in capacity has a negative impact on costs and fees collected. The putwall has been identified as a constraint and is the first focus of optimization. 46% of POs generate putwall usage and partial shipment receipts drive allocation based putwall usages.",
    goal: 'In order to improve the throughput in cases per day, the NDC optimization project aims to examine operational constraints at the NDC, starting with the put wall, to reduce labor cost and increase capacity to allow for more fee collection.',
    valueStory:
      'Maximize efficiency / throughput and monetary value / fee generation at the NDC, including identifying volume through facility optimization with fees and charges, manufacturers who should be charged more, and products that shouldn’t move through',
    valueRealization: [
      'Revenue Growth - $12M in potential In new fee opportunity by optimizing the NDC putwall ',
      'Revenue Growth - $12.2M in incremental NDC fee potential identified via harmonization models'
    ],
    linkHeader: 'Link to Product',
    linkLabel: 'NDC Optimization Suite',
    link: 'https://witty-ground-0fe0fba0f.4.azurestaticapps.net/',
    relatedProjects: ['product-segmentation', 'supplier-risk-mapping'],
    tools: [azure, databricks, vectorDatabase],
    enablers: [mL],
    featured: true
  },
  oam: {
    key: 'oam',
    type: 'Product',
    title: 'Omit Allocation Management (OAM)',
    businessCase:
      'Ongoing generic drug shortages have become increasingly disruptive to ABC’s pharmacy customers as key areas of care are impacted. Drug allocation management should leverage optimized and prescriptive data analytics to minimize disruptions wherever possible.',
    problemStatement:
      'The omission of product from orders has the potential to lead to poor patient outcomes at ABC’s customers, particularly where life-saving generic injectables are omitted. In addition, ABC is incurring an estimated $50M per year in omits for products on allocation. Omits lead to customer frustration and ABC’s allocation processes are highly manual, reactive, subjective, based on only the prior 90 days of purchase history, and overall are sub-optimal in approach. ',
    goal: "OAM's focus is to provide a real-time optimal product allocation that reduces (or eliminates) anticipated product omits. The intent is to better serve our customers through reduced omits and ease manual management of product allocation for short supply products.",
    valueStory: 'Evolve current allocation methodology to reduce products in short supply and optimize products shipped and served ',
    valueRealization: ['Improved Experience - 19.5% Service Level ', 'Improvement of raw line service level for materials placed on OAM'],
    linkHeader: 'Link to Product',
    linkLabel: 'Omit Allocation Management (OAM)',
    link: 'https://app.powerbi.com/groups/e0553436-6cc7-4a18-aad8-6a7a20849680/reports/e4dbe8a5-883f-4660-9bba-f2926a891864/ReportSectionfa26258c956521961e03?experience=power-bi',
    relatedProjects: ['operating-income', 'supplier-risk-mapping'],
    tools: [azure, databricks, powerBI],
    enablers: [mL]
  },
  pad: {
    key: 'pad',
    type: 'Product',
    title: 'Product Availability Dashboard (PAD)',
    sponsor: 'US Pharmaceutical and US Supply Chain',
    businessCase:
      'Senior business leaders must rapidly answer questions concerning product availability at the customer and product level to make decisions and drive actions to reduce omits. Clearly defined metrics using omit data along with other data such as service level, sales, alt serves, and market data will allow these insights to be realized.',
    problemStatement:
      'A lack of clarity exists, across departments, for defining an actionable or non-actionable omits, assigning actionable responsibility, and communicating working activities. Disparate data sets cause long lead times when assigning actions to reduce omits.',
    goal: 'Construct a comprehensive data analysis tool for use across Cencora as one version of the truth for omits. This dashboard will be tailored to senior leadership, allowing ease of situational analysis in order to assign and drive actions to responsible parties to reduce omits.',
    valueStory:
      'Provide enterprise visibility to large data sets at scale to provide a next day assessment of operational performance of order fulfillment using enterprise certified metrics ',
    valueRealization: ['Improved Experience '],
    linkHeader: 'Link to Product',
    linkLabel: 'Product Availability Dashboard (PAD)',
    link: 'https://app.powerbi.com/groups/0d87b3e3-5248-4eba-9b2a-6650b2ed43d6/reports/c8d231ff-9b7d-4545-989d-bc9ae7dc2483/ReportSection025156e71a00454c44d2?experience=power-bi',
    relatedProjects: ['product-segmentation'],
    tools: [azure, databricks, powerBI, python],
    enablers: [mL],
    featured: true
  },
  'receiving-prioritization-insights': {
    key: 'receiving-prioritization-insights',
    type: 'Product',
    title: 'Receiving Prioritization Insights',
    sponsor: 'Commercial Operations, Replenishment Operations',
    businessCase:
      'We need to enable and empower Cencora’s key strategic Supply Chain and Replenishment Operation teams to accurately calculate the volume of on-deck receiving inventory and prioritize the receiving inventory picks to reduce the likelihood of omits.',
    problemStatement:
      'Periodically, distribution centers across Cencora’s network get behind on receiving materials into physical inventory, causing high-demand materials to appear low or out of stock when Cencora has received the product from the manufacturer.',
    goal: [
      'To achieve this goal, a comprehensive pilot solution will be created to include the following innovation and technological capabilities: ',
      '- Near-real-time data streaming capabilities from time-critical sources',
      '- A flexible data modeling framework on ED&A’s existing infrastructure',
      '- Front-end component for visualizing and interacting with the data outputs'
    ],
    valueStory:
      'Utilize a real time data analytics tool to identify when product has been physically received but not scheduled for expedited receipt into inventory',
    valueRealization: ['Improved Experience', 'Risk Management / Avoidance'],
    linkHeader: 'Link to Product',
    linkLabel: 'Receiving Prioritization Insights ',
    link: 'https://eda-stg.amerisourcebergen.com/ReceivingPrioritization/overview',
    relatedProjects: [],
    tools: [azure, databricks, python, react],
    enablers: [bV]
  },
  smartsource: {
    key: 'smartsource',
    type: 'Product',
    title: 'SmartSource',
    businessCase:
      'Ongoing generic drug shortages have become increasingly disruptive to pharmacy customers as key areas of care are impacted. Smartsource is a valuable service that addresses product shortages and omits by providing a dedicated formulary.',
    problemStatement:
      'Most of WAG full line omits received by DC15 goes unfilled is due not enough product in stock or not having right product at DC15.The missed opportunity to serve our customers leads to a lower service level in the full line distribution centers.  It also creates opportunities for our customers to buy GRx products from our competitors.  ',
    goal: 'The goal of this project to predict WAG full line omits in order to anticipate DC15 demand. Smartsource predicative model will focus on the following drivers in order to increase fulfillment rate and total profit dollars.',
    valueStory:
      'Integrate decision making for cross functional teams by providing advanced analytics to optimize the primary distribution methodology on profitability (as opposed to product availability); Predicts product shortages, aligns on formulary, and identifies inventory availability at the SmartSource Distribution Centers',
    valueRealization: [
      'Revenue Growth - $7.8M SmartSource revenue from Seasonal buys and Allocations (Realized)',
      'Revenue Growth - $5.7M SmartSource operating income from Allocation tool (Realized)',
      'Risk Avoidance - $68.8M High/Medium risk inventory transferred out of DC15 (Realized)',
      'Risk Avoidance - $15M SmartSource Buy Analysis Recommendations (Potential)'
    ],
    linkHeader: 'Link to Product',
    linkLabel: 'SmartSource',
    link: 'https://app.powerbi.com/groups/me/apps/a2e84541-52ef-47e2-9fcd-c4fbb8401ce4/reports/90bed514-d7fc-4188-b67a-74a360960ceb/ReportSectiona688043ed915d58c9361?experience=power-bi',
    relatedProjects: ['operating-income', 'product-segmentation', 'supplier-risk-mapping'],
    tools: [azure, databricks, powerBI],
    enablers: [mL]
  },
  scrm: {
    key: 'scrm',
    type: 'Product',
    title: 'Supply Chain Resiliency Mapping (SCRM)',
    sponsor: 'Strategic Global Sourcing and Operations',
    businessCase:
      'We need to proactively identify potential supply chain risks, foster improved communication and collaboration with suppliers, and inform robust risk mitigation strategies. In the face of unforeseen events, this will enable rapid response through real-time data analytics and the implementation of contingency plans for agile decision-making and adaptation. ',
    problemStatement: 'How can we standardize supplier risk to proactively manage supplier performance and inform risk mitigation?',
    goal: 'Leveraging our advanced technological capabilities and access to relevant data sets, we can comprehensively map our upstream supply chain. Ultimately, these efforts lead to enhanced supply chain resiliency, ensuring increased product availability, elevated service levels, and timely deliveries for Cencora customers and their patients.',
    valueStory:
      'Provide advanced analytics to create a supplier management operating model that measures and tracks supplier performance and risk to inform Cencora’s strategic partnerships',
    valueRealization: ['Risk Management / Avoidance', 'Improved Experience'],
    linkHeader: 'Link to Product',
    linkLabel: 'Supply Chain Resiliency Mapping (SCRM)',
    link: 'https://app.powerbi.com/groups/e0553436-6cc7-4a18-aad8-6a7a20849680/reports/68746389-549f-47e4-bb75-fbcbd5fc837f/ReportSectionedadf64d5208ec3cca49?experience=power-bi',
    relatedProjects: [
      'alt-serve',
      'customer-360',
      'customer-segmentation',
      'operating-income',
      'pad',
      'product-segmentation',
      'supplier-risk-mapping'
    ],
    tools: [azure, databricks, neo4j, python, spark, react, vectorDatabase],
    enablers: [genAI, kG, mL],
    featured: true
  }
};

export const decisionModels = {
  'alt-serve': {
    key: 'alt-serve',
    type: 'Decision Model',
    title: 'Alt Serve',
    sponsor: 'Enterprise and Tiger Teams',
    businessCase:
      'We need to have clearly defined metrics to rapidly answer questions concerning product availability at the customer and product level to make decisions and drive actions to reduce omits using omit data along with other data such as service level, sales, alt serves, and market data.',
    problemStatement:
      'The existing Alt Serve process lacks automatic consideration of all inventory within the network; Potentially causing an omit or requiring the customer to contact customer service, creating prolonged processing times and inefficiencies for customer service. ​',
    goal: 'Establish intelligent Alt Serve logic that provides an improved solution for product to be shipped to a customer from a DC other than their primary location based on optimized cost to serve considerations and inventory levels. Assess technology platforms to automate the order management process to support future state logic.​',
    valueStory:
      'Optimize the Alt Serve experience to determine the appropriate criteria by which an item not available in a primary Distribution Center will be substituted by the next best identified solution to meet the customer need',
    valueRealization: ['Improved Experience', 'Revenue Growth'],
    relatedProjects: ['pad'],
    tools: [azure, databricks, powerBI, python],
    enablers: [mL]
  },
  'customer-360': {
    key: 'customer-360',
    type: 'Decision Model',
    title: 'Customer 360 ',
    sponsor: 'Customer Operations, Enterprise',
    businessCase:
      'We need a customer identity graph solution to create a unified customer data platform serving as a single, trusted view of customers enriched with flexible business rules and embedded semantic relationships.',
    problemStatement:
      'Traditional solutions built on relational database management systems are good tools for indexing and searching data but are poorly equipped to connect across tables and business entities. ',
    goal: 'The outcome will be the opportunity to drive customer engagement, identify upselling opportunities, and enhance customer service experience.',
    valueStory:
      'Provide an integrated, trusted view of a customer, including attributes such as interactions with Cencora, operational performance, and financials',
    valueRealization: ['Improved Experience', 'Revenue Growth'],
    relatedProjects: ['product-segmentation'],
    tools: [azure, databricks, neo4j, python],
    enablers: [kG, mL]
  },
  cpm: {
    key: 'cpm',
    type: 'Decision Model',
    title: 'Customer Profit Modeling (CPM)',
    sponsor: 'Finance',
    businessCase:
      'We need a connected, flexible, and intelligent data storage and data processing engine that establishes complex relationships between varied data sources. This will lead to the connection of data across the enterprise to produce deeper and comprehensive insights that powers effective decision making.',
    problemStatement:
      'The current solution is fragmented and tangled between manual excel processes and some governed automation within Vendavo. This process creates a lack of standardization and governance with limited ability for optimal portfolio analysis. ​',
    goal: 'The goal of this project is to streamline, integrate, and bolster key data elements, sales, finance, and contracting processes to drive strategic insights, enhance portfolio analytics, influence deal strategy, and answer key commercial questions.​',
    valueStory: 'Partner with Finance to enable optimal Customer Profit Modeling portfolio analysis with flexibility and standardization',
    valueRealization: ['Improved Experience', 'Revenue Growth'],
    relatedProjects: [],
    tools: [powerBI],
    enablers: [playbooks]
  },
  'customer-segmentation': {
    key: 'customer-segmentation',
    type: 'Decision Model',
    title: 'Customer Segmentation',
    sponsor: 'Health Systems, Enterprise',
    businessCase:
      'We ought to use graph database algorithms to generate relationships between customers and products, which will allow us to cluster groups of similar customers and/or products using similarity indices.',
    problemStatement:
      'Businesses are striving to better understand customer buying patterns. However, traditional methods of customer segmentation are not always interpretable or feasible with large amounts of data.',
    goal: 'To provide actionable insights to inform product recommendation, targeted marketing, and flexible analytics workflows to leverage with other use cases.',
    valueStory:
      'Stratify customer segments leveraging the Customer 360 model and additional analytics that group customers according to specified attributes or pattern behavioral patterns',
    valueRealization: ['Improved Experience', 'Revenue Growth'],
    relatedProjects: ['customer-360', 'scrm', 'my-community-my-pharmacy'],
    tools: [azure, databricks, neo4j, python],
    enablers: [kG, mL]
  },
  'enterprise-certified-metrics': {
    key: 'enterprise-certified-metrics',
    type: 'Decision Model',
    title: 'Enterprise Certified Metrics',
    sponsor: 'Enterprise',
    businessCase:
      'We ought to have an established enterprise definition and score data against defined quality metrics to assess accuracy, timeliness, completeness, consistency, and accessibility to ensure data is fit-for-use.',
    problemStatement: 'Recognized need for standard definitions across the enterprise.​',
    goal: [
      '- Certify critical data elements across the organization ',
      '- Increase analytics accuracy and give decision-makers reliable information ',
      '- Have common data definitions published for a shared understanding of data',
      '- Avoid inconsistent data silos in different departments and business units'
    ],
    valueStory:
      'Establish key calculations for the organization that have been reviewed by appropriate stakeholders as the enterprise standard; Enterprise definition and score data is used to assess accuracy, timeliness, completeness, consistency, and accessibility to ensure data is fit-for-use',
    valueRealization: ['Improved Experience'],
    relatedProjects: [
      'alt-serve',
      'customer-360',
      'customer-segmentation',
      'operating-income',
      'pad',
      'product-segmentation',
      'supplier-risk-mapping'
    ],
    tools: [],
    enablers: [playbooks, bEV]
  },
  'operating-income': {
    key: 'operating-income',
    type: 'Decision Model',
    title: 'Operating Income',
    sponsor: 'Financial Operations and Enterprise',
    businessCase:
      'We need improved accuracy and granularity of our customer profitability metrics. By exposing the true cost to serve our customers and streamlining our financial calculations, business leaders will be able uncover hidden sources of value and more quicky react to changes.',
    problemStatement:
      'Current profitability metrics are narrowly focused on gross profit (i.e., price less COGS). However, such metrics ignore activity-based costs such as additional supplies needed for cold chain items, lower throughput of manual pick zones, and unreimbursed sale & use taxes, to name a few. In addition, the current lack of automation and standardization substantially slows the release of our internal financial statements.',
    goal: 'This model allocates the monthly operating expenses (NDC, Full Line/Specialty FDCs, Customer Operations, and unreimbursed Sales & Use Taxes), fixed costs (DS Administration, Operations Excellence, Drug Eliminations, Drug Non-Allocated, and FDC fixed costs), and profit (sales less returns and discounts, WBAD, PRxO) to each customer at the 1X hierarchical level and segment following agreed upon methodologies across all segments serving the U.S. human health supply chain.',
    valueStory: 'Provide an operating income allocations model for the logistic order fulfillment process down to the customer 1K level',
    valueRealization: ['Taxes Charge Back Opportunity - $25M in In unrecovered taxes discovered (Potential)'],
    relatedProjects: ['bid-tool', 'oam', 'smartsource', 'scrm'],
    tools: [azure, databricks, powerBI, python],
    enablers: [mL]
  },
  'product-segmentation': {
    key: 'product-segmentation',
    type: 'Decision Model',
    title: 'Product Segmentation',
    sponsor: 'Enterprise',

    businessCase: 'Leveraging similarities in products to improve our product availability, customer service, and profitability. ​',
    problemStatement: 'Every product is treated independently even though there are similar attributes, customers, and demand patterns. ​',
    goal: ['- Reduced inventory carrying cost​', '- Improved product availability', '- Reduced omits​', '- Improved customer service​'],
    valueStory:
      'Review the portfolio of items sold by Cencora based on supply and demand characteristics to determine a more optimized inventory strategy ',
    valueRealization: ['Improved Experience', 'Revenue Growth'],
    relatedProjects: ['ndc', 'pad', 'smartsource', 'scrm'],
    tools: [azure, databricks, neo4j, powerBI, python],
    enablers: [mL]
  },
  'supplier-risk-mapping': {
    key: 'supplier-risk-mapping',
    type: 'Decision Model',
    title: 'Supplier Risk Mapping',
    sponsor: 'SGS and US Supply Chain, Enterprise',
    businessCase:
      'We need to leverage graph database technologies to integrate operational data as graph network to optimize the flow of goods, uncover vulnerabilities, and boost resilience. ',
    problemStatement:
      'Minimal data assets for mapping and understanding the upstream supply chain and related risks. As a result, there are less opportunities to manage supplier risks or quickly adapt to supply disruptions. ',
    goal: [
      '- Increase operational efficiencies with adaptive risk measures',
      '- Automate risk identification and mitigation to reduce stockouts'
    ],
    valueStory:
      'Assess risks to product availability by incorporating multiple risk criteria to determine probabilistic supply disruptions',
    valueRealization: ['Risk Management / Avoidance', 'Improved Experience'],
    relatedProjects: ['bid-tool', 'dscsa', 'ndc', 'oam', 'smartsource', 'scrm'],
    tools: [azure, databricks, powerBI, python, vectorDatabase],
    enablers: [mL, genAI]
  }
};

export default {
  ...products,
  ...decisionModels
};
