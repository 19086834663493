import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { jwtDecode } from 'jwt-decode';
import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: 'd62e066a-36de-47aa-a6da-35df0bfd72ef',
    authority: 'https://login.microsoftonline.com/0b5ac31e-e17a-4021-9d04-550f86320765',
    redirectUri: process.env.REACT_APP_LANDING_ROUTE
  }
};

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  roles: []
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| AD CONTEXT & PROVIDER ||============================== //

const ActiveDirectoryContext = createContext(null);

const getUserDetails = async (token) => {
  const response = await axios.get('https://graph.microsoft.com/v1.0/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};

const getUserSQLPermissions = async (token) => {
  const response = await axios.get('https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$select=id', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
};

export const ActiveDirectoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    if (process.env.REACT_APP_RUN_LOCALLY === 'true') {
      const local_user_roles = ['admin', 'Projects.Read', 'DistCenter.Read'];
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            name: 'Local User',
            email: 'localuser@example.com',
            token: null,
            id: 'localid',
            title: 'Local User Title',
            AD_groups: ['All'],
            roles: local_user_roles
          },
          roles: local_user_roles
        }
      });
    } else {
      const init = async () => {
        try {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length > 0) {
            const account = accounts[0];

            // Token for Microsoft Graph
            const graphResponse = await acquireTokenSilent(account, ['User.Read']);
            const userDetails = await getUserDetails(graphResponse.accessToken);
            const userSQL = await getUserSQLPermissions(graphResponse.accessToken);

            // Token for your application to get roles
            const appResponse = await acquireTokenSilent(account, ['d62e066a-36de-47aa-a6da-35df0bfd72ef/.default']);

            const decodedToken = jwtDecode(appResponse.accessToken);
            const roles = decodedToken.roles || [];

            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user: {
                  name: account.name,
                  email: account.username,
                  token: graphResponse.accessToken,
                  id: userDetails.id,
                  title: userDetails.jobTitle,
                  roles: roles,
                  AD_groups: userSQL.value
                }
              }
            });
          } else {
            dispatch({
              type: LOGOUT
            });
          }
        } catch (err) {
          console.error(err);
          dispatch({
            type: LOGOUT
          });
        }
      };
      init();
    }
  }, []);

  const login = async () => {
    const loginRequest = {
      scopes: ['User.Read']
    };

    try {
      const authResult = await msalInstance.loginPopup(loginRequest);

      const userDetails = await getUserDetails(authResult.accessToken);
      const userSQL = await getUserSQLPermissions(authResult.accessToken);

      let name = authResult.account.name;
      try {
        const parts = name.split(',').map((part) => part.trim());
        name = parts.reverse().join(' ');
      } catch (err) {
        console.warn('Failed to reformat name, using default format:', err);
      }

      const appResponse = await acquireTokenSilent(authResult.account, ['d62e066a-36de-47aa-a6da-35df0bfd72ef/.default']);

      const decodedToken = jwtDecode(appResponse.accessToken);
      const roles = decodedToken.roles || [];
      setSession(authResult.accessToken);

      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            name: name,
            email: authResult.account.username,
            token: authResult.accessToken,
            id: authResult.uniqueId,
            title: userDetails.jobTitle,
            roles: roles,
            AD_groups: userSQL.value
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const acquireTokenSilent = async (account, scopes) => {
    return await msalInstance.acquireTokenSilent({
      account: account,
      scopes: scopes
    });
  };

  const logout = () => {
    msalInstance.logoutPopup();

    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <ActiveDirectoryContext.Provider value={{ ...state, login, logout, updateProfile }}>{children}</ActiveDirectoryContext.Provider>;
};

ActiveDirectoryProvider.propTypes = {
  children: PropTypes.node
};

export default ActiveDirectoryContext;
