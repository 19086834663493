import PropTypes from 'prop-types';

// project import
import { FlagsProvider as Flags } from 'react-feature-flags';

// ==============================|| FLAGS PROVIDER ||============================== //

const featureFlags = [{ name: 'isDarkModeEnabled', isActive: false }];

export const FlagsProvider = ({ children }) => {
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  const isStage = process.env.REACT_APP_ENVIRONMENT === 'stage';
  const isDemo = process.env.REACT_APP_ENVIRONMENT === 'demo';
  const isPrd = process.env.REACT_APP_ENVIRONMENT === 'prd';

  const envFlags = [
    { name: 'dev', isActive: isDev },
    { name: 'stage', isActive: isStage },
    { name: 'demo', isActive: isDemo },
    { name: 'prd', isActive: isPrd }
  ];

  const allFlags = [...envFlags, ...featureFlags];
  return <Flags value={allFlags}>{children}</Flags>;
};

FlagsProvider.propTypes = {
  children: PropTypes.node
};
