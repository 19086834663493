import { Button, Divider, Typography, Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import governance from 'data/governance';

const DataGovernanceDropdown = () => {
  const theme = useTheme();

  return (
    <Grid container padding="32px 0px">
      {/* -------------------- Intro Column -------------------- */}
      <Grid item xs={3.5} color="white">
        <Typography variant="h5">
          <Link
            href={governance.config.route}
            underline="none"
            sx={{
              color: theme.palette.common.white,
              ':hover': {
                color: theme.palette.secondary.main
              },
              cursor: 'pointer'
            }}
          >
            Data Governance
          </Link>
        </Typography>
        <Typography variant="body2">{governance.config.vision.content}</Typography>
        <Button
          sx={{
            display: 'block',
            color: theme.palette.common.white,
            ':hover': {
              color: theme.palette.secondary.main
            },
            marginTop: '15px'
          }}
          href={governance.config.route}
        >
          Learn about Data Governance practices
        </Button>
      </Grid>

      {/* -------------------- DG Pages -------------------- */}
      <Grid item xs={1} alignItems="center" justifyContent="center" display="flex">
        <Divider orientation="vertical" variant="middle" color={theme.palette.primary.main} sx={{ width: 2, minHeight: 175 }} />
      </Grid>

      {/* -------------------- DG Pages -------------------- */}
      <Grid item xs={7.5}>
        <Grid container spacing={8}>
          {Object.values(governance.pages).map((item, index) => (
            <Grid item xs={6} key={index}>
              <Typography variant="h5">
                <Link
                  href={item.link}
                  underline="none"
                  sx={{
                    color: theme.palette.common.white,
                    ':hover': {
                      color: theme.palette.secondary.main
                    },
                    cursor: 'pointer'
                  }}
                >
                  {item.title}
                </Link>
              </Typography>
              <Typography variant="body2" color="white" mb={6}>
                {item.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataGovernanceDropdown;
