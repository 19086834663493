// material-ui
import { Box } from '@mui/material';

// project import
import NavDropdown from './NavDropdown';
import NavButton from './NavButton';

const dropdownPages = [
  { name: 'Portfolio', path: '/portfolio' },
  { name: 'Data Governance', path: '/governance' }
];

// ==============================|| HEADER CONTENT - NAVIGATION TABS ||============================== //

const NavigationTabs = () => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
      <NavButton label="About" href="/about" />
      {dropdownPages.map((page) => (
        <NavDropdown key={page.name} label={page.name} path={page.path} />
      ))}
    </Box>
  );
};

export default NavigationTabs;
