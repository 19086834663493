// ==============================|| IMPORTS ||============================== //
// general
import { Outlet } from 'react-router-dom';

// material-ui
import { Box, Container, Toolbar } from '@mui/material';

// project
import Header from './Header';
import Footer from './Footer';

import useConfig from 'hooks/useConfig';

// ==============================|| FEEDBACK LAYOUT ||============================== //

const FeedbackLayout = () => {
  const { container } = useConfig();

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      <Box component="main" sx={{ width: 'calc(100%)', flexGrow: 1 }}>
        <Toolbar />

        <Container
          maxWidth="100%"
          sx={{
            ...(container && { px: { xs: 0, sm: 0 } }),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
};

export default FeedbackLayout;
