import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useMemo, lazy } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Loadable from 'components/Loadable';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Tab, Tabs, Typography } from '@mui/material';

// project import
import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import { Flags } from 'react-feature-flags';
const Transitions = Loadable(lazy(() => import('components/@extended/Transitions')));

import { ThemeMode } from 'config';
import useAuth from 'hooks/useAuth';

// assets
import defaultAvatar from 'assets/images/common/avatar/default-avatar.png';
import defaultAvatarBlue from 'assets/images/common/avatar/default-avatar-blue.png';
import defaultAvatarGreen from 'assets/images/common/avatar/default-avatar-green.png';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { logout, user } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const defaultAvatarImages = useMemo(() => [defaultAvatar, defaultAvatarBlue, defaultAvatarGreen], []);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    const getProfilePhoto = async () => {
      if (user && user.token) {
        try {
          const response = await axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              Authorization: `Bearer ${user.token}`
            },
            responseType: 'arraybuffer'
          });

          let raw = Buffer.from(response.data, 'binary').toString('base64');
          setAvatar(`data:${response.headers['content-type']};base64,${raw}`);
        } catch (error) {
          console.error('Failed to fetch user profile photo:', error);
          setAvatar(defaultAvatarImages[Math.floor(Math.random() * defaultAvatarImages.length)]);
        }
      }
    };

    getProfilePhoto();
  }, [user, defaultAvatarImages]);

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.300' : 'grey.300'; // open dark mode : open light mode

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100', // closed dark mode : closed light mode
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === ThemeMode.DARK ? 'gray.200' : 'gray.300' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={avatar} size="xs" />
          <Typography variant="button" color="text.primary">
            {user?.name}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <Avatar alt="profile user" src={avatar} sx={{ width: 32, height: 32 }} />
                          <Stack>
                            <Typography variant="body1">{user?.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {user.title}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Account"
                        {...a11yProps(0)}
                      />
                      <Flags authorizedFlags={['isDarkModeEnabled']}>
                        <Tab
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textTransform: 'capitalize'
                          }}
                          icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                          label="Settings"
                          {...a11yProps(1)}
                        />
                      </Flags>
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab handleLogout={handleLogout} />
                  </TabPanel>
                  <Flags authorizedFlags={['isDarkModeEnabled']}>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                      <SettingTab />
                    </TabPanel>
                  </Flags>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
