import { useNavigate } from 'react-router-dom';

// material-ui
import { ButtonBase, Stack } from '@mui/material';

// project import
import Profile from './Profile';

// image import
import cencoraLogo from '../../../../assets/images/dark/avif/logos/logo.avif';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleButtonClick = () => {
    navigate('/');
  };

  return (
    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
      <Stack spacing={2} direction="row">
        <ButtonBase sx={{ mr: 1 }} onClick={handleButtonClick}>
          <img src={cencoraLogo} alt={'homelogo'} width="100" />
        </ButtonBase>
      </Stack>

      <Profile />
    </Stack>
  );
};

export default HeaderContent;
