import { useState } from 'react';
import { Box, Button, Typography, Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import projects, { products, decisionModels } from 'data/projects';
import { getAbsoluteProjectPath } from 'utils/projects';
import PropTypes from 'prop-types';

const portfolioInnerMenuOptions = {
  decisionModels: 'Decision Models',
  featured: 'Featured',
  products: 'Products'
};

const PortfolioDropdown = ({ height }) => {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState('Featured');
  const [hoveredItem, setHoveredItem] = useState(null);

  const onInnerMenuItemClick = (item) => {
    event.stopPropagation();
    setSelectedItem(item);
  };

  const handleButtonHover = (item) => {
    setHoveredItem(item);
  };

  const featuredItems = Object.values(projects).filter((project) => project.featured === true);

  return (
    <Box sx={{ width: 1, color: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            pt: 4,
            borderRight: 1,
            color: '#DFDBE9',
            textAlign: 'center',
            width: 0.18,
            height: height * 0.95
          }}
        >
          <PortfolioDropdownInnerNavOption
            name={portfolioInnerMenuOptions.featured}
            itemHovered={hoveredItem}
            isSelected={selectedItem === portfolioInnerMenuOptions.featured}
            isHovered={hoveredItem === portfolioInnerMenuOptions.featured}
            onClick={() => onInnerMenuItemClick(portfolioInnerMenuOptions.featured)}
            onMouseEnter={() => handleButtonHover(portfolioInnerMenuOptions.featured)}
            onMouseLeave={() => handleButtonHover(null)}
          />
          <PortfolioDropdownInnerNavOption
            name={portfolioInnerMenuOptions.products}
            itemHovered={hoveredItem}
            isHovered={hoveredItem === portfolioInnerMenuOptions.products}
            isSelected={selectedItem === portfolioInnerMenuOptions.products}
            onClick={() => onInnerMenuItemClick(portfolioInnerMenuOptions.products)}
            onMouseEnter={() => handleButtonHover(portfolioInnerMenuOptions.products)}
            onMouseLeave={() => handleButtonHover(null)}
          />
          <PortfolioDropdownInnerNavOption
            name={portfolioInnerMenuOptions.decisionModels}
            itemHovered={hoveredItem}
            isHovered={hoveredItem === portfolioInnerMenuOptions.decisionModels}
            isSelected={selectedItem === portfolioInnerMenuOptions.decisionModels}
            onClick={() => onInnerMenuItemClick(portfolioInnerMenuOptions.decisionModels)}
            onMouseEnter={() => handleButtonHover(portfolioInnerMenuOptions.decisionModels)}
            onMouseLeave={() => handleButtonHover(null)}
          />
        </Box>

        <Box
          sx={{
            pt: 4,
            pl: 4,
            pr: 1,
            color: theme.palette.common.white,
            maxHeight: '60vh',
            overflow: 'auto',
            width: 0.8,
            scrollbarColor: `${theme.palette.background.paper} ${theme.palette.primary.darker}`,
            scrollbarWidth: 'thin'
          }}
        >
          {selectedItem === portfolioInnerMenuOptions.featured && <PortfolioDropdownSection items={featuredItems} />}
          {selectedItem === portfolioInnerMenuOptions.products && <PortfolioDropdownSection items={Object.values(products)} />}
          {selectedItem === portfolioInnerMenuOptions.decisionModels && <PortfolioDropdownSection items={Object.values(decisionModels)} />}
        </Box>
      </Box>
    </Box>
  );
};

PortfolioDropdown.propTypes = {
  height: PropTypes.number
};

export default PortfolioDropdown;

const PortfolioDropdownSection = ({ items }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={6} key={index}>
          <Typography variant="h6">
            <Link
              href={getAbsoluteProjectPath(item)}
              underline="none"
              sx={{
                color: theme.palette.common.white,
                ':hover': {
                  color: theme.palette.secondary.main
                },
                cursor: 'pointer'
              }}
            >
              {item.title}
            </Link>
          </Typography>
          <Typography variant="body2" mb={4}>
            {item.valueStory}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

PortfolioDropdownSection.propTypes = {
  items: PropTypes.array
};

const PortfolioDropdownInnerNavOption = ({ name, itemHovered, isSelected, isHovered, onClick, onMouseEnter, onMouseLeave }) => {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        display: 'block',
        marginBottom: 1,
        color: isSelected
          ? isHovered
            ? theme.palette.secondary.main
            : itemHovered === null
              ? theme.palette.secondary.main
              : theme.palette.common.white
          : isHovered
            ? theme.palette.secondary.main
            : theme.palette.common.white,
        padding: '0 8px',
        ':hover': {
          color: theme.palette.secondary.main
        }
      }}
    >
      {name}
    </Button>
  );
};

PortfolioDropdownInnerNavOption.propTypes = {
  name: PropTypes.string,
  itemHovered: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHovered: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};
