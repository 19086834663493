import { useState } from 'react';

import ThemeSwitch from 'components/molecules/controls/ThemeSwitch';
import { ThemeMode } from 'config';
import { useTheme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText, FormControlLabel, FormGroup } from '@mui/material';

// assets
import { CommentOutlined, QuestionCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Flags } from 'react-feature-flags';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const { mode, onChangeMode } = useConfig();

  const handleModeChange = (event) => {
    onChangeMode(event.target.checked ? ThemeMode.DARK : ThemeMode.LIGHT);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
        <ListItemIcon>
          <QuestionCircleOutlined />
        </ListItemIcon>
        <ListItemText primary="Dark Mode" />
        <FormGroup row>
          <FormControlLabel
            sx={{ marginRight: { sm: '0px' } }}
            control={<ThemeSwitch onChange={handleModeChange} checked={mode === ThemeMode.DARK} theme={theme} />}
          />
        </FormGroup>
      </ListItemButton>
      <Flags authorizedFlags={['dev', 'admin']}>
        <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
          <ListItemIcon>
            <CommentOutlined />
          </ListItemIcon>
          <ListItemText primary="Feedback" />
        </ListItemButton>
      </Flags>
      <Flags authorizedFlags={['dev', 'admin']}>
        <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
          <ListItemIcon>
            <UnorderedListOutlined />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItemButton>
      </Flags>
    </List>
  );
};

export default SettingTab;
