import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Toolbar } from '@mui/material';

// project import
import FooterActions from './FooterActions';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => {
  const theme = useTheme();

  // footer content
  const footerActions = useMemo(() => <FooterActions />, []);

  const toolbarStyle = {
    display: 'flex',
    flexDirection: 'column', // Set flexDirection to 'column'
    backgroundColor: theme.palette.primary.dark,
    bottom: 0,
    zIndex: 1200,
    padding: '4vh 8vw'
  };

  return (
    <Toolbar style={toolbarStyle}>
      <Stack spacing="24px" width="100%">
        {footerActions}
      </Stack>
    </Toolbar>
  );
};

export default Footer;
