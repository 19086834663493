import PropTypes from 'prop-types';
import { useEffect, useMemo, lazy, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import Loadable from 'components/Loadable';

const MaintenanceError401 = Loadable(lazy(() => import('../../pages/maintenance/401')));

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = (props) => {
  const [unauthorized, setUnauthorized] = useState(true);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const requiredRoles = props.requiredRoles;

  const hasRequiredRole = useMemo(
    () => !requiredRoles || user?.roles?.includes('Admin') || user?.roles?.some((role) => requiredRoles.includes(role)),
    [user, requiredRoles]
  );

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('login', {
        state: { from: location.pathname },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  useEffect(() => {
    if (isLoggedIn && requiredRoles?.length > 0 && !hasRequiredRole) {
      setUnauthorized(true);
    } else {
      setUnauthorized(false);
    }
    setLoading(false);
  }, [isLoggedIn, hasRequiredRole, requiredRoles, navigate]);

  if (loading) {
    null;
  } else if (unauthorized) {
    return <MaintenanceError401 />;
  }

  return props.children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  requiredRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])])
};

export default AuthGuard;
