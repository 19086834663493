// material-ui
import { Grid } from '@mui/material';

// project import
import FooterColumn from './FooterColumn';
import projects from 'data/projects';
import { getAbsoluteProjectPath } from 'utils/projects';

// ==============================|| Footer Links - MAIN ||============================== //

const getProjectGroups = () => {
  const groups = {
    general: { group: { title: 'ED&A' }, pages: [{ title: 'About Us', link: '/about' }] },
    capabilities: { group: { title: 'Capabilities' }, pages: [{ title: 'Data Governance', link: '/governance' }] },
    products: { group: { title: 'Products' }, pages: [] },
    'decision-models': { group: { title: 'Decision Models' }, pages: [] }
  };
  for (const project of Object.values(projects)) {
    const data = { title: project.title, link: getAbsoluteProjectPath(project) };
    if (project.type === 'Product') {
      groups.products.pages.push(data);
    } else if (project.type === 'Decision Model') {
      groups['decision-models'].pages.push(data);
    }
  }
  return groups;
};

const projectGroups = Object.values(getProjectGroups());

const FooterLinks = () => {
  return (
    <Grid container direction="row" spacing={0}>
      {projectGroups.map((projectGroup) => [
        <FooterColumn key={projectGroup.group.title} group={projectGroup.group} pages={projectGroup.pages} />
      ])}
    </Grid>
  );
};

export default FooterLinks;
