import PropTypes from 'prop-types';

// material-ui
import { Button, Grid, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ==============================|| Footer Column - MAIN ||============================== //

const FooterColumn = ({ group, pages }) => {
  const theme = useTheme();
  return (
    <Grid item paddingRight="20px" xs={6} lg={3}>
      <Typography variant="h6" color={theme.palette.common.white} sx={{ cursor: 'default', display: 'inline-block' }}>
        {group.title}
      </Typography>

      <Stack padding="24px 0px 40px 0px" spacing="16px" alignItems="left" justifyContent="flex-start">
        {pages.map((page) => [
          <Button
            key={page.title}
            href={page.link}
            variant="text"
            display="inline-block"
            sx={{
              ':hover': {
                color: theme.palette.secondary.main
              },
              height: 'auto'
            }}
          >
            <Typography variant="body2" sx={{ cursor: 'pointer', display: 'inline-block' }}>
              {page.title}
            </Typography>
          </Button>
        ])}
      </Stack>
    </Grid>
  );
};

FooterColumn.propTypes = {
  group: PropTypes.object,
  pages: PropTypes.array
};

export default FooterColumn;
