import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useMediaQuery } from '@mui/material';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import ReagraphTheme from './reagraph';
import componentsOverride from './overrides';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
  const { themeDirection, mode, presetColor, fontFamily } = useConfig();

  const theme = useMemo(() => Palette(mode, presetColor), [mode, presetColor]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = useMemo(() => Typography(fontFamily), [fontFamily]);
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
  const themeReagraphTheme = useMemo(() => ReagraphTheme(theme), [theme]);

  /* --------------------------------------------------------------------- */
  /* -------------------- value story and realization -------------------- */
  /* --------------------------------------------------------------------- */

  // const to help determine props based on screensize
  const isDownLG = useMediaQuery(theme.breakpoints.down('lg'), {
    defaultMatches: true
  });

  const themeOptions = useMemo(() => {
    // function to help determine left and right padding
    const subSectionPadding = '48px';
    const leftRightPadding = (subSectionCount, isEnd) => {
      const totalPaddingPer = (parseFloat(subSectionPadding) * (subSectionCount - 1)) / subSectionCount;
      if (isEnd) {
        return `${totalPaddingPer}px`;
      } else {
        return `${totalPaddingPer / 2}px`;
      }
    };

    return {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      reagraphTheme: themeReagraphTheme,
      customProps: {
        isDownLG: isDownLG,
        subSectionPadding: subSectionPadding,
        leftRightPadding: leftRightPadding
      }
    };
  }, [themeDirection, theme, themeTypography, themeCustomShadows, themeReagraphTheme, isDownLG]);

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node
};
