// material-ui
import { Grid, Typography } from '@mui/material';

// ==============================|| Footer Actions - MAIN ||============================== //

const FooterActions = () => {
  return (
    <Grid container direction="row" justifyContent="flex-end">
      {/* Disclaimer */}
      <Grid item xs={12}>
        <Typography variant="body2" color="white">
          ED&A data is confidential. Do not share outside organization.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterActions;
