// ==============================|| IMPORTS ||============================== //

import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import FeedbackLayout from 'layout/FeedbackLayout';
import GuestGuard from 'utils/route-guard/GuestGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loadable from 'components/Loadable';

// ==============================|| ROUTES ||============================== //

// Login Routes
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));

// Error Routes
const NotFoundError = Loadable(lazy(() => import('pages/maintenance/404')));

// Project Routes
const About = Loadable(lazy(() => import('pages/about/About')));
const ProjectPage = Loadable(lazy(() => import('pages/projects/Project')));

const Landing = Loadable(lazy(() => import('pages/Landing')));

const PortfolioPage = Loadable(lazy(() => import('pages/portfolio/index')));
const GovAbout = Loadable(lazy(() => import('pages/datagovernance/AboutPage')));
const GovPolicies = Loadable(lazy(() => import('pages/datagovernance/Policies')));
const GovMS = Loadable(lazy(() => import('pages/datagovernance/MeasuringSuccess')));
const GovDQ = Loadable(lazy(() => import('pages/datagovernance/DataQuality')));
const GovImplementation = Loadable(lazy(() => import('pages/datagovernance/ImplementationGuide')));

// Feedback Routes
const FeedbackForm = Loadable(lazy(() => import('pages/feedback/Form')));
const FeedbackFormResponse = Loadable(lazy(() => import('pages/feedback/FormResponse')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const getRequiredRoles = (path) => {
    const route = path.split('/')[1];
    switch (route) {
      case 'feedback':
        return ['Internal.Read'];
      default:
        return null;
    }
  };

  const requiredRoles = getRequiredRoles(location.pathname);

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <AuthGuard requiredRoles={requiredRoles}>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: '/',
              element: <Landing />
            },
            {
              path: '/about',
              element: <About />
            },
            {
              path: '/portfolio',
              element: <PortfolioPage />
            },
            {
              path: '/portfolio/:project_id',
              element: <ProjectPage />
            },
            {
              path: '/governance',
              element: <GovAbout />
            },
            {
              path: '/governance/measuring-success',
              element: <GovMS />
            },
            {
              path: '/governance/data-quality',
              element: <GovDQ />
            },
            {
              path: '/governance/policies',
              element: <GovPolicies />
            },
            {
              path: '/governance/implementation-guide',
              element: <GovImplementation />
            }
          ]
        }
      ]
    },
    {
      path: '/feedback',
      element: (
        <AuthGuard requiredRoles={requiredRoles}>
          <FeedbackLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/feedback',
          element: <FeedbackForm />
        },
        {
          path: '/feedback/formResponse',
          element: <FeedbackFormResponse />
        }
      ]
    },
    {
      //Login Routes - Do not modify
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <CommonLayout />
            </GuestGuard>
          ),
          children: [
            {
              path: '/',
              element: <AuthLogin />
            },
            {
              path: 'login',
              element: <AuthLogin />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: (
        <AuthGuard requiredRoles={requiredRoles}>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '*',
          element: <NotFoundError />
        }
      ]
    }
  ]);
}
