import AzureIcon from '@assets/images/common/icons/azure1.svg';
import PowerBIIcon from '@assets/images/common/icons/power-bi-2021.svg';
import PythonIcon from '@assets/images/common/icons/python.svg';
import DatabricksIcon from '@assets/images/common/icons/Databricks.svg';
import KafkaIcon from '@assets/images/common/icons/apache_kafka-icon.svg';
import ReactIcon from '@assets/images/common/icons/react.svg';
import AIIcon from '@assets/images/common/icons/Batch-AI.svg';
import mLIcon from '@assets/images/common/icons/Machine-Learning.svg';
import SparkIcon from '@assets/images/common/icons/apache_spark-icon.svg';
import Neo4jIcon from '@assets/images/common/icons/neo4j-icon.svg';
import KGIcon from '@assets/images/common/icons/knowledge-graph.svg';
import VectorIcon from '@assets/images/common/icons/database.svg';
import PlaybooksIcon from '@assets/images/common/icons/playbooks.svg';
import bVIcon from '@assets/images/common/icons/visualization.png';
import bEVIcon from '@assets/images/common/icons/behaviorEconomics.png';

export const azure = { name: 'Azure', description: 'Infrastructure', icon: AzureIcon };

export const python = { name: 'Python', description: 'Code / ML', icon: PythonIcon };

export const powerBI = { name: 'PowerBI', description: 'Visualization', icon: PowerBIIcon };

export const databricks = { name: 'Databricks', description: 'Code / ML', icon: DatabricksIcon };

export const kafka = { name: 'Kafka', description: 'Code / ML', icon: KafkaIcon };

export const neo4j = { name: 'Neo4j', description: 'Code / ML', icon: Neo4jIcon };

export const react = { name: 'React', description: 'Code', icon: ReactIcon };

export const spark = { name: 'Spark', description: 'Code / ML', icon: SparkIcon };

export const vectorDatabase = { name: 'Vector Database', description: 'Infrastructure', icon: VectorIcon };

export const mL = { name: 'Machine Learning', description: 'ML', icon: mLIcon };

export const genAI = { name: 'GenAI & AI', description: 'Code / ML', icon: AIIcon };

export const kG = { name: 'Knowledge Graphs', description: 'Infrastructure', icon: KGIcon };

export const bV = { name: 'Behavioral Visualizations', description: 'Visualization', icon: bVIcon };

export const playbooks = { name: 'Playbooks', description: 'Code / ML', icon: PlaybooksIcon };

export const bEV = { name: 'Behavioral Economics & Visualizations', description: 'Visualization', icon: bEVIcon };
