export const BASE_ROUTE = '/governance';

export const vision = {
  content:
    'We are a data governance center of excellence that helps the enterprise drive better, data-led decisions and outcomes by enabling the management of critical data and improving confidence and transparency to create trusted sources of data.'
};

export const pages = {
  policies: {
    title: 'Policies and Standards',
    content:
      'Enterprise Data Governance policies and standards set forth high-level data governance directives for Cencora to ensure management of critical data assets.',
    link: '/governance/policies',
    related: []
  },
  'implementation-guide': {
    title: 'Implementation Guide',
    content:
      'A starting guide for leaders across Cencora to put Data Governance policies and standards into action and understand how to manage data assets effectively.',
    link: '/governance/implementation-guide',
    related: ['policies']
  },
  'measuring-success': {
    title: 'Measuring Success',
    content:
      'A guide to evaluating process quality, data quality, and adoption of Data Governance practices to determine the success of our Enterprise Data Governance transformation.',
    link: '/governance/measuring-success',
    related: ['policies', 'implementation-guide', 'data-quality']
  },
  'data-quality': {
    title: 'Data Quality',
    content:
      'Define the dimensions of high quality data and how the Data Quality lifecycle should be used to ensure that the data in use by systems at Cencora is managed effectively.',
    link: '/governance/data-quality',
    related: ['implementation-guide', 'measuring-success']
  }
};

export default {
  pages,
  config: {
    vision,
    route: BASE_ROUTE
  }
};
