// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //
import '../fonts/fonts.css';
import { useTheme } from '@mui/material';

// calculate the size based on a set scale
const scaleBase = 1;
const scaleRatio = 1.3;
export const calcSize = (factor) => {
  return `calc(${scaleBase * scaleRatio ** factor}rem)`;
};

export default function Typography() {
  const theme = useTheme();
  return {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12
        },
        h1: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 1.125,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(5)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(6)
          }
        },
        h2: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 1.125,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(4)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(5)
          }
        },
        h3: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: 1.125,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(3)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(4)
          }
        },
        h4: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 1.167,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(3)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(4)
          }
        },
        h5: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 1.167,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(2)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(3)
          }
        },
        h6: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 1.167,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(1)
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: calcSize(2)
          }
        },
        body1: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: calcSize(1),
          lineHeight: 1.5
        },
        body2: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: calcSize(0),
          lineHeight: 1.5,
          cursor: 'default'
        },
        body3: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: calcSize(-1),
          lineHeight: 1.5,
          cursor: 'default'
        },
        caption: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'medium',
          fontSize: calcSize(-2),
          lineHeight: 1.5,
          cursor: 'default'
        },
        subtitle1: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: calcSize(1),
          lineHeight: 1.5,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(-2)
          }
        },
        subtitle2: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: calcSize(-2),
          lineHeight: 1.5,
          cursor: 'default',
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(-3)
          }
        },
        overline: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: calcSize(-2),
          lineHeight: 1.5,
          [theme.breakpoints.down('lg')]: {
            fontSize: calcSize(-3)
          }
        },
        button: {
          fontFamily: 'ABgilroy',
          fontStyle: 'normal',
          fontWeight: 'bold',
          lineHeight: 1.66,
          cursor: 'pointer'
        },
        cursor: 'default'
      }
    }
  };
}
