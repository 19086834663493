import { useMemo } from 'react';

// material-ui
import { AppBar, Toolbar } from '@mui/material';

// project imports
import HeaderContent from './HeaderContent';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  // common header
  const mainHeader = <Toolbar>{headerContent}</Toolbar>;

  const appBar = {
    position: 'fixed',
    color: 'primary',
    elevation: 0,
    sx: {
      zIndex: 1300,
      width: '100%'
    }
  };

  return (
    <>
      <AppBar {...appBar}>{mainHeader}</AppBar>
    </>
  );
};

export default Header;
