import { forwardRef } from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const NavButton = forwardRef((props, ref) => {
  const theme = useTheme();
  const label = props.label;
  const isdropdown = props.isdropdown;
  return (
    <Button
      ref={ref}
      {...props}
      variant="text"
      sx={{
        color: theme.palette.common.white,
        height: '42px',
        padding: '0px 16px',
        variant: 'text',
        ':hover': {
          color: theme.palette.secondary.main
        }
      }}
    >
      {label}
      {isdropdown && <ArrowDropDownIcon sx={{ maxHeight: '1.2rem' }} />}
    </Button>
  );
});

NavButton.propTypes = {
  label: PropTypes.string,
  isdropdown: PropTypes.string
};

export default NavButton;
